<template>
    <div class="flex">
        <span class="p-input-icon-right mr-1" style="width: 20%;">
            <i class="pi pi-search" @click="abrirFiltroAvancado()" v-if="!disabled" />
            <InputText 
                :disabled="disabled" 
                :class="{ 'p-invalid': invalid }"
                class="w-full"
                placeholder="Nr. Contrato"
                v-model="selecionado[codigo]"
                @keyup.enter="selecionar"
                @change="selecionar"
            />
        </span>

        <InputText 
            :disabled="true"
            :class="{ 'p-invalid': invalid }"
            style="width: 80%;"
            v-model="selecionado[descricao]"
            placeholder="Cliente"
        />
    </div>

    <Dialog modal header="Buscar Contrato" v-model:visible="filtroAvancado" :style="{width: '60%'}">

        <DataTable 
            @sort="sort($event)"
            showGridlines
            selectionMode="single"
            responsiveLayout="stack"
            :value="paginacao.content"
            v-model:selection="selecionadoNaListagem"
            @row-select="selecionar">

            <template #header>
                <div class="grid p-fluid formgrid align-items-center">
                    <div class="col-12 lg:col-2">
                        <div class="field">
                            <label for="nr-ci">
                                <strong>Nro. Contrato Interno</strong>
                            </label>
                            <InputNumber id="nr-ci" class="w-full" v-model="filtros.contratoInternoId" placeholder="Número contrato"/>
                        </div>
                    </div>

                    <div class="col-12 lg:col-2">
                        <div class="field">
                            <label for="nr-contrato-rep">
                                <strong>Nro. Contrato Corretora</strong>
                            </label>
                            <InputText id="nr-contrato-rep" class="w-full" v-model="filtros.contratoCorretora" placeholder="Número contrato"/>
                        </div>
                    </div>

                    <div class="col-12 lg:col-2" v-if="!!this.pesquisaContratoJuridico">
                      <div class="field">
                        <label for="nr-ci">
                          <strong>Nro. Contrato Jurídico</strong>
                        </label>
                        <InputText id="nr-cj" class="w-full" v-model="filtros.contratoJuridico" placeholder="Número contrato"/>
                      </div>
                    </div>

                    <div class="col-12 lg:col-2">
                        <div class="field">
                            <label for="data-emissao">
                                <strong>Data Emissão</strong>
                            </label>
                            <Calendar class="w-full" id="data-emissao" v-model="dataContrato" dateFormat="dd/mm/yy"/>
                        </div>
                    </div>

                    <div class="col-12 lg:col-4">
                        <div class="field">
                            <label>
                                <strong>Corretora</strong>
                            </label>
                            <CorretoraZoom
                                codigo="codigoCliente"
                                descricao="nome"
                                :selecionado="corretoraSelecionada"
                                :disabled="false"
                                :paginacao="corretoraConsulta"
                                @pesquisar="pesquisarCorretora"
                                @selecionado="selecionarCorretora"
                            />
                        </div>
                    </div>

                    <div class="col-12 lg:col-6">
                        <div class="field">
                            <label>
                                <strong>Comprador</strong>
                            </label>
                            <ProdutorFiltro
                                tituloModal="Buscar Comprador"
                                :disabled="false"
                                :campoObrigatorio="true"
                                :selecionado="compradorSelecionado"
                                :paginacao="compradorConsulta"
                                @pesquisar="pesquisarComprador"
                                @selecionado="selecionarComprador"
                            />
                        </div>
                    </div>

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button
                                label="Filtrar"
                                icon="pi pi-filter"
                                class="p-button-outlined p-button-info"
                                @click="pesquisar"
                            ></Button>

                            <Button 
                                title="Limpar Filtro"
                                icon="pi pi-filter-slash"
                                class="p-button-outlined p-button-info"
                                style="margin-left: 1px;"
                                @click="limparFiltro"
                            ></Button>
                        </div>
                    </div>

                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="contratoInternoId" header="Nro. Contrato Interno" style="padding-top: 10px; padding-bottom: 10px;" sortable/>      
            <Column field="contratoCorretora" header="Nro. Contrato Corretora" sortable/>
            <Column field="contratoJuridico" header="Nro. Contrato Jurídico" sortable v-if="!!pesquisaContratoJuridico"/>

            <Column field="dataControtato" header="Data emissão" sortable>
                <template #body="{ data, field }">
                    {{ formatDateCustom(data[field], "DD/MM/YYYY") }}
                </template>
            </Column>

            <Column field="corretoraDto" header="Corretora" sortable>
                <template #body="{ data, field }">
                    {{ data[field].codigoCliente + " - " + data[field].nome }}
                </template>
            </Column>

            <Column field="comprador" header="Comprador" sortable>
                <template #body="{ data, field }">
                    {{ data[field].codigo + " - " + data[field].nome }}
                </template>
            </Column>
        </DataTable>

        <Paginator
            :rows="filtros.size"
            :totalRecords="totalRegistro"
            :rowsPerPageOptions="[1, 5, 10]"
            @page="onPage"/>
    </Dialog>
</template>

<script>
import CorretoraZoom from './CorretoraZoom.vue';
import Formatacao from '../../utilities/Formatacao';
import ProdutorFiltro from '../cadastro/ProdutorFiltro.vue';
import CorretoraService from '../../service/CorretoraService';
import ProdutorService from '../../service/ProdutorService';

export default {
    components: { CorretoraZoom, ProdutorFiltro },

    emits: ["pesquisar", "selecionado"],

    props: {
        selecionado: {
            type: Object,
            required: true
        },
        paginacao: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        },
        invalid: {
            type: Boolean,
            required: false
        },
        codigo: {
            type: String,
            required: true
        },
        descricao: {
            type: String,
            required: true
        },
        pesquisaContratoJuridico: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            filtros: {},
            totalRegistro: 0,
            filtroAvancado: false,
            selecionadoNaListagem: null,

            dataContrato: null,
            corretoraSelecionada: {},
            compradorSelecionado: {},
            compradorConsulta: {},
            corretoraConsulta: {}
        }
    },

    updated() {
        if(!!this.paginacao.pageable && !!this.paginacao.totalPages) {
            const qtdRegistros = this.paginacao.pageable.pageSize;
            this.totalRegistro = this.paginacao.totalPages * qtdRegistros;
        }
    },

    methods: {
        pesquisar() {

            this.filtros["corretoraId"] = undefined;
            this.filtros["codigoComprador"] = undefined;
            this.filtros["dataContratoInicio"] = undefined;
            this.filtros["dataContratoFim"] = undefined;

            if(!!this.corretoraSelecionada && !!this.corretoraSelecionada.id) {
                this.filtros["corretoraId"] = this.corretoraSelecionada.id;
            }

            if(!!this.compradorSelecionado && !!this.compradorSelecionado.codProdutor) {
                this.filtros["codigoComprador"] = this.compradorSelecionado.codProdutor;
            }

            if(!!this.dataContrato) {
                const data = this.formatDateCustom(this.dataContrato, "YYYY-MM-DD");
                this.filtros["dataContratoInicio"] = data;
                this.filtros["dataContratoFim"] = data;
            }

            this.$emit("pesquisar", this.filtros);
        },

        selecionar() {
            const codigo = !!this.selecionadoNaListagem ? this.selecionadoNaListagem[this.codigo] : this.selecionado[this.codigo];
            let registro = null;

            if(!!this.registro) {
                registro = Object.assign({}, this.registro);
            }
            
            this.$emit('selecionado', codigo, registro, !!this.selecionadoNaListagem);
            this.fecharFiltroAvancado();
        },

        abrirFiltroAvancado() {
            this.filtros = {};
            this.filtros["size"] = 10;
            this.filtros["page"] = 0;
            this.filtros["sort"] = 'id,asc';
            
            this.pesquisar();

            this.filtroAvancado = true;
        },

        fecharFiltroAvancado() {
            this.selecionadoNaListagem = null;
            this.filtroAvancado = false;
        },

        limparFiltro() {
            this.filtros["contratoInternoId"] = undefined;
            this.filtros["corretoraId"] = undefined;
            this.filtros["contratoCorretora"] = undefined;
            this.filtros["contratoJuridico"] = undefined;
            this.filtros["codigoComprador"] = undefined;
            this.filtros["dataContratoInicio"] = undefined;
            this.filtros["dataContratoFim"] = undefined;

            this.dataContrato = null;
            this.corretoraSelecionada = {};
            this.compradorSelecionado = {};

            this.pesquisar();
        },

        onPage(event) {
            this.filtros["page"] = event.page + 1;
            this.filtros["size"] = event.rows;
            this.pesquisar();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.filtros["sort"] = `${ sortField },${sortOrder}`;
            this.pesquisar();
        },

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },
        
        pesquisarCorretora(filtros) {
            CorretoraService.buscarComPaginacao(filtros)
                .then(({ data }) => {
                    if(data) {
                        this.corretoraConsulta = data;
                    }
                })
                .catch(error => {
                    this.corretoraConsulta = {}

                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar as corretoras.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                        toast["severity"] = 'warn';
                    }

                    this.$toast.add(toast);
                });
        },

        selecionarCorretora(codigo) {
            if(codigo == undefined || codigo == null || codigo.trim() == "") {
                this.corretoraSelecionada = {};
                return;
            }

            CorretoraService.buscarPorCodigoCliente(codigo)
                .then(({ data }) => {
                    this.corretoraSelecionada = data;
                })
                .catch(error => {
                    this.corretoraSelecionada = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar corretora.`,
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;

                        if(!!message) {
                            toast["detail"] = message;
                            toast["severity"] = 'warn';
                        }
                    }

                    this.$toast.add(toast);
                });
        },

        pesquisarComprador(filtros) {
            ProdutorService.pesquisarProdutor(filtros)
                .then(({ data }) => {
                    if(data) {
                        this.compradorConsulta = data;
                    }
                })
                .catch(error => {
                    this.compradorConsulta = {}

                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar os compradores.',
                        detail: error, 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                        toast["severity"] = 'warn';
                    }

                    this.$toast.add(toast);
                });
        },

        selecionarComprador(codProdutor) {
            if(codProdutor == undefined || codProdutor == null || codProdutor.trim() == "") {
                this.compradorSelecionado = {};
                return;
            }

            ProdutorService.buscarPorCodProdutorInfoContrato(codProdutor)
                .then(({ data }) => {
                    if(data) {
                        this.compradorSelecionado = data;
                    }
                })
                .catch(error => {
                    this.compradorSelecionado = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar o comprador.`,
                        detail: error, 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        if(!!message) {
                            toast["detail"] = message.replace("produtor", "comprador");
                        }
                        toast["severity"] = 'warn';
                    }

                    this.$toast.add(toast);
                });
        }
    }
}
</script>